import { useDispatch, useSelector } from "react-redux"
import { getActiveDistance, getEuclideanSimilarity, getEuclideanValue, getManhattanSimilarity, getManhattanValue, setActiveDistance, setEuclideanValue, setManhattanValue } from "../../features/dashboard/similaritySlice"
import { Threshold } from "./Threshold"
import { SimilarityResult } from "./SimilarityResult"

export const Similarity = () => {

    const dispatch = useDispatch()
    const activeTab = useSelector(getActiveDistance)
    const euclideanSimilarity = useSelector(getEuclideanSimilarity)
    const manhattanSimilarity = useSelector(getManhattanSimilarity)
    const euclideanTollerance = useSelector(getEuclideanValue)
    const manhattanTollerance = useSelector(getManhattanValue)


    return (
        <div className="col-12 my-box mb-4 similarity-test">
            <div className="heading">
                <h2>Teste de Similaridade | Encontre o Limiar</h2>
            </div>
            <div className="row info mb-3">
                <div className="col-sm-12">
                    <p>Ao selecionar um limite (tolerância) como entrada, o sistema realiza cerca de 225.000 comparações entre rostos pertencentes à mesma pessoa e a pessoas diferentes, calcula a distância e estabelece quais comparações são consideradas válidas, ou seja, cuja distância é menor que o limite indicado se os rostos pertencem à mesma pessoa ou superiores para pessoas diferentes.</p>
                    <p>Entre as comparações com falha, é mostrado o número de falsos positivos (rostos pertencentes a pessoas diferentes que seriam aceitos) e falsos negativos (rostos pertencentes à mesma pessoa que seriam rejeitados).</p>
                    <p>Maiores detalhes estão disponíveis no relatório anexo ao projeto.</p>
                </div>
            </div>
            <div className="row">
                <div className="col-sm-12">
                    <ul className="nav nav-tabs">
                        <li className="nav-item">
                            <p
                                className={'nav-link ' + (activeTab === 'euclidean' ? 'active' : '')} 
                                onClick={() => dispatch(setActiveDistance('euclidean'))}
                            >Algoritmo - Euclidean</p>
                        </li>
                        <li className="nav-item">
                            <p
                                className={'nav-link ' + (activeTab === 'manhattan' ? 'active' : '')}onClick={() => dispatch(setActiveDistance('manhattan'))}
                            >Algoritmo - Manhattan</p>
                        </li>
                    </ul>
                </div>
            </div>
            <div className="row tab-panel px-3">
                <div className={'col-sm-12 ' + (activeTab === 'euclidean' ? '' : 'hidden')}>
                    <Threshold
                        target='euclidean'
                        threshold={euclideanTollerance}
                        setThreshold={setEuclideanValue}
                    />
                    <SimilarityResult target='euclidean' similarity={euclideanSimilarity} />
                </div>
                <div className={'col-sm-12 ' + (activeTab === 'manhattan' ? '' : 'hidden')}>
                    <Threshold
                        target='manhattan'
                        threshold={manhattanTollerance}
                        setThreshold={setManhattanValue}
                    />
                    <SimilarityResult target='manhattan' similarity={manhattanSimilarity} />
                </div>
            </div>
        </div>
    )
}